import { graphql, Link } from 'gatsby';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import React from 'react';
import { ContentWrapper } from '../components/content_wrapper/content_wrapper';
import { SEO } from '../components/seo';
import { GatsbyPageProps } from '../gatsby-types';
import Layout from '../layouts/layout';
import { RoutePaths } from '../lib/routes';
import * as styles from './series.module.less';

export const seriesFragment = graphql`
  fragment PrismicSeriesFragment on PrismicSeries {
    data {
      display_order
      name {
        raw
        text
      }
      tag_name
    }
    uid
  }
`;

export const query = graphql`
  {
    allPrismicSeries(sort: { fields: data___display_order }) {
      nodes {
        ...PrismicSeriesFragment
      }
    }
  }
`;

export interface PrismicSeries {
  uid: string;
  data: {
    display_order: number;
    name: {
      raw: RichTextBlock[];
      text: string;
    };
    tag_name: string;
  };
}

const SeriesListPage = (props: GatsbyPageProps) => {
  const series = props.data.allPrismicSeries.nodes as PrismicSeries[];
  return (
    <Layout>
      <SEO title="Series" />
      <ContentWrapper>
        <article>
          <h1>Series</h1>
          <ul className={styles.seriesList}>
            {series.map((serie) => (
              <li key={serie.uid} className={styles.series}>
                <Link to={`${RoutePaths.SERIES}${serie.uid}`} className={styles.seriesLink}>
                  <span className={styles.seriesName}>{RichText.asText(serie.data.name.raw)}</span>
                </Link>
              </li>
            ))}
          </ul>
        </article>
      </ContentWrapper>
    </Layout>
  );
};

export default SeriesListPage;
